<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">档案管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">一企一档</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">档案目录</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="年份" class="searchboxItem ci-full">
              <span class="itemLabel">年份:</span>
              <el-date-picker
                v-model="value3"
                type="year"
                size="small"
                :picker-options="pickerOptions0"
                placeholder="选择年"
              >
              </el-date-picker>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="目录"
                align="left"
                prop="dictValue"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.dictValue }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="80px">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="handlLook(scope.row)"
                    >查看</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialog"
      :title="title"
      top="5%"
      width="65%"
      :center="true"
      :close="doClose"
    >
      <div class="archives_box">
        <div id="pdf-contents" style="height: 600px"></div>
      </div>
    </el-dialog>
     <editPopup ref="editPopup"  :editPopupData="editPopupData"/>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import pdf from "pdfobject";
import editPopup from "@/components/editPopup.vue"; 
export default {
  name: "OneEnterpriseArchivesJg",
  components: {
    Empty,
    editPopup
  },
  mixins: [List],
  data() {
    return {
      pickerOptions0: {
        disabledDate(time) {
          var myDate = new Date();
          var tYear = myDate.getFullYear();
          return time.getFullYear() > tYear;
        },
      },
      value3: "",
      tableData: [],
      dialog: false,
      editPopupDialogVisible:false,
      title: "",
    };
  },
  created() {
    var date = new Date();
    this.value3 = new Date(String(date.getFullYear()));
    this.getData2();
  },
  computed: {},
  methods: {
    getData2() {
      this.$post("/dm/enterprise/record/category/list")
        .then((ret) => {
          this.tableData = ret.data;
        })
        .catch((err) => {
          return;
        });
    },
    //编辑
    handlLook(row) {
      this.title = row.dictValue;
      let parmar = {
        year: new Date(this.value3.getTime()).Format("yy"),
        enterpriseId: this.$route.query.enterpriseId,
        docType: row.dictCode,
        compId: this.$route.query.compId,
      };
      if (row.dictCode == "200") {
        this.downloadUrl = "/dm/enterprise/record/institution/download";
        // 205-关于任命安全教育主管部门和人员的文件；
      } else if (row.dictCode == "205") {
        this.downloadUrl = "/dm/enterprise/record/appoint/download";
        // 215-培训需求调查表；
      } else if (row.dictCode == "215") {
        this.downloadUrl = "/dm/enterprise/record/survey/download";
        // 240-培训条件证明材料
      } else if (row.dictCode == "240") {
        this.downloadUrl = "/dm/enterprise/record/demonstrate/download";
      }
      // this.title = row.dictValue;
      this.dictCode = row.dictCode;
      if (row.dictCode == "210") {
        this.title = this.$route.query.enterpriseName + row.dictValue;
        this.dialog = true;
        this.handlDownloadThreePost();
      }
      if (row.dictCode == "220") {
        this.title = row.dictValue;
        this.dialog = true;
        this.handlDownloadTeacherAccount();
      }
      if (row.dictCode == "225") {
        this.title =
          new Date(this.value3.getTime()).Format("yy") + row.dictValue;
        this.dialog = true;
        this.handlDownloadTrainPlan();
      }
      if (row.dictCode == "230") {
        this.title =
          new Date(this.value3.getTime()).Format("yy") + row.dictValue;
        this.dialog = true;
        this.handlDownloadTrainingEducation();
      }
      if (row.dictCode == "235") {
        this.title =
          new Date(this.value3.getTime()).Format("yy") + row.dictValue;
        this.dialog = true;
        this.handlDownloadcapitalAmount();
      }
      if (
        row.dictCode == "200" ||
        row.dictCode == "205" ||
        row.dictCode == "215" ||
        row.dictCode == "240"
      ) {
       this.editPopupData = {
          compId: this.$route.query.compId,
          enterpriseId: this.$route.query.enterpriseId,
          docType: row.dictCode,
          year: new Date(this.value3.getTime()).Format("yy"),
          btnShow:false,
          title:row.dictValue
        };
        this.$refs.editPopup.showPopUp(this.editPopupData);
        //  this.$confirm('该文件为zip包,请点击下载查看', '查看', {
        //   confirmButtonText: '确定',
        //   confirmButtonClass: "bgc-bv",
        //   showCancelButton:false,
        // }).then(() => {
        //    this.$post(this.downloadUrl, parmar).then((res) => {
        //       window.open(res.data);
        //     });
        // }).catch(() => {
                  
        // });
      }
    },
    /* 210 */
    handlDownloadThreePost() {
      let parmar = {
        year: new Date(this.value3.getTime()).Format("yy"),
        enterpriseId: this.$route.query.enterpriseId,
        docType: this.dictCode,
        compId: this.$route.query.compId,
      };
      this.$post("/biz/document/three-post/downloadThreePost", parmar)
        .then((ret) => {
          if (ret.status == "0") {
            var options = {
              pdfOpenParams: {
                pagemode: "thumbs",
                toolbar: 0,
                navpanes: 0,
                statesbar: 0,
                view: "FitV",
                scrollbars: "0",
              },
            };
            this.$nextTick(() => {
              pdf.embed(ret.data.queryFileUrl, "#pdf-contents", options);
            });
          } else {
            this.$message.warning({ message: res.message, duration: 1500 });
          }
        })
        .catch((err) => {
          return;
        });
    },
    /* 220 */
    handlDownloadTeacherAccount() {
      let parmar = {
        year: new Date(this.value3.getTime()).Format("yy"),
        enterpriseId: this.$route.query.enterpriseId,
        docType: this.dictCode,
        compId: this.$route.query.compId,
      };
      this.$post("/biz/document/teacher-account/downloadTeacherAccount", parmar)
        .then((ret) => {
          if (ret.status == "0") {
            var options = {
              pdfOpenParams: {
                pagemode: "thumbs",
                toolbar: 0,
                navpanes: 0,
                statesbar: 0,
                view: "FitV",
                scrollbars: "0",
              },
            };
            this.$nextTick(() => {
              pdf.embed(ret.data.queryFileUrl, "#pdf-contents", options);
            });
          } else {
            this.$message.warning({ message: res.message, duration: 1500 });
          }
        })
        .catch((err) => {
          return;
        });
    },
    /* 225 */
    handlDownloadTrainPlan() {
      let parmar = {
        year: new Date(this.value3.getTime()).Format("yy"),
        enterpriseId: this.$route.query.enterpriseId,
        docType: this.dictCode,
        compId: this.$route.query.compId,
      };
      this.$post("/biz/document/annual-train-plan/downloadTrainPlan", parmar)
        .then((ret) => {
          if (ret.status == "0") {
            var options = {
              pdfOpenParams: {
                pagemode: "thumbs",
                toolbar: 0,
                navpanes: 0,
                statesbar: 0,
                view: "FitV",
                scrollbars: "0",
              },
            };
            this.$nextTick(() => {
              pdf.embed(ret.data.queryFileUrl, "#pdf-contents", options);
            });
          } else {
            this.$message.warning({ message: res.message, duration: 1500 });
          }
        })
        .catch((err) => {
          return;
        });
    },
    /* 230 */
    handlDownloadTrainingEducation() {
      let parmar = {
        year: new Date(this.value3.getTime()).Format("yy"),
        enterpriseId: this.$route.query.enterpriseId,
        docType: this.dictCode,
        compId: this.$route.query.compId,
      };
      this.$post("/biz/dm/AnnualSafetyTrainLedger/download", parmar)
        .then((ret) => {
          if (ret.status == "0") {
            var options = {
              pdfOpenParams: {
                pagemode: "thumbs",
                toolbar: 0,
                navpanes: 0,
                statesbar: 0,
                view: "FitV",
                scrollbars: "0",
              },
            };
            this.$nextTick(() => {
              pdf.embed(ret.data.url, "#pdf-contents", options);
            });
          } else {
            this.$message.warning({ message: res.message, duration: 1500 });
          }
        })
        .catch((err) => {
          return;
        });
    },
    /* 235 */
    handlDownloadcapitalAmount() {
      let parmar = {
        year: new Date(this.value3.getTime()).Format("yy"),
        enterpriseId: this.$route.query.enterpriseId,
        docType: this.dictCode,
        compId: this.$route.query.compId,
      };
      this.$post("/biz/document/capital-amount/downloadCapitalAmount", parmar)
        .then((ret) => {
          if (ret.status == "0") {
            var options = {
              pdfOpenParams: {
                pagemode: "thumbs",
                toolbar: 0,
                navpanes: 0,
                statesbar: 0,
                view: "FitV",
                scrollbars: "0",
              },
            };
            this.$nextTick(() => {
              pdf.embed(ret.data.queryFileUrl, "#pdf-contents", options);
            });
          } else {
            this.$message.warning({ message: res.message, duration: 1500 });
          }
        })
        .catch((err) => {
          return;
        });
    },
    doClose() {
      this.dialog = false;
    }
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        } else {
          this.getData();
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less">
.dialogBox {
  height: 600px;
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.75rem 0;
    h3 {
      padding-bottom: 0.75rem;
    }
  }
  .divBox2 {
    width: 100%;
  }
}
</style>
